// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successful-tutorships-main-container {
  overflow: auto;
  height: 500px;
  max-height: 500px;
}
.successful-tutorships-main-container .table-container {
  overflow: auto;
  height: 100%;
}
.successful-tutorships-main-container .table-container th {
  width: 61px;
}`, "",{"version":3,"sources":["webpack://./src/features/statistics/components/SuccessfulTutorships/SuccessfulTutorships.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,aAAA;EACA,iBAAA;AACJ;AACI;EACI,cAAA;EACA,YAAA;AACR;AACQ;EACI,WAAA;AACZ","sourcesContent":[".successful-tutorships-main-container {\n    overflow: auto;\n    height: 500px;\n    max-height: 500px;\n\n    .table-container {\n        overflow: auto;\n        height: 100%;\n\n        th {\n            width: 61px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
