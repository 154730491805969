import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTutorsAvailableForStudyGroups,
  saveStudyGroupTutorRequest,
  createOrUpdateTutor,
  getTutors,
  removeTutorById,
  patchTutor,
  getAllTutorInfoById,
} from './tutorService';

export const sendStudyGroupTutorRequest = createAsyncThunk(
  'tutors/sendStudyGroupTutorRequest',
  /** @param {{[key: string]: any}} tutorData */
  async (tutorData) => {
    return await saveStudyGroupTutorRequest(tutorData);
  }
);

export const fetchTutorsAvailableForStudyGroups = createAsyncThunk('tutors/fetchAvailableForStudyGroups', async () => {
  return await getTutorsAvailableForStudyGroups();
});

export const fetchTutors = createAsyncThunk('tutor/fetchTutors', async () => {
  return await getTutors();
});

export const fetchAllTutorInfoById = createAsyncThunk(
  'tutor/fetchAllTutorInfoById',
  /** @param {number} tutorId */
  async (tutorId) => {
    return await getAllTutorInfoById(tutorId);
  }
);

export const registerOrUpdateTutor = createAsyncThunk(
  'tutor/registerNewTutor',
  /** @param {any} newTutorData */
  async (newTutorData) => {
    return await createOrUpdateTutor(newTutorData);
  }
);

export const updateTutor = createAsyncThunk(
  'tutor/updateTutor',
  /** @param {any} newTutorData */
  async (newTutorData) => {
    return await patchTutor(newTutorData);
  }
);

export const removeTutor = createAsyncThunk(
  'tutor/delete',
  /** @param {number} tutorId */
  async (tutorId) => {
    return await removeTutorById(tutorId);
  }
);
