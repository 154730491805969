import React, { useEffect, useState } from 'react';

import './QualitativeEvaluations.scss';

import SubjectsMenu from './components/SubjectsMenu';
import CategoryCard from './components/CategoryCard';
import CommentsCard from './components/CommentsCard';

const QualitativeEvaluations = ({ categories, data, isLoading = null }) => {
  const [currentSubject, setCurrentSubject] = useState('');

  useEffect(() => {
    if (!data) return;
    if (Object.keys(data).length <= 0) return;

    setCurrentSubject(Object.keys(data)[0]);
  }, [data]);

  return (
    <div className="qualitative-evaluations-main-container card shadow mb-4 text-gray-900">
      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-center">
        <h5 className="m-0 font-weight-bold">Valoraciones</h5>
      </div>

      <div className="card-body">
        {isLoading ? (
          <h5 className="text-center">Cargando...</h5>
        ) : !data || !(currentSubject in data) || Object.keys(data || {}).length <= 0 ? (
          <h5 className="text-center">No tenemos datos para este apartado</h5>
        ) : (
          <div className="row justify-content-center h-100">
            <div className="col-10 h-100">
              <div className="qualitative-evaluations-subjects-menu-external-container">
                <SubjectsMenu
                  subjects={Object.keys(data)}
                  currentSubject={currentSubject}
                  onClick={setCurrentSubject}
                />
              </div>
              <div className="row qualitative-evaluations-category-cards-external-container">
                {categories.map((category, i) => (
                  <CategoryCard
                    icon={category.icon}
                    label={category.label}
                    description={category.description}
                    data={data[currentSubject]['categories'][i]}
                    color={category.color}
                    key={i}
                  />
                ))}
                {<CommentsCard comments={data[currentSubject]['comments']} />}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QualitativeEvaluations;
