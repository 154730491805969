import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import { sidebarItems } from './sidebarItems';
import { useSelector } from 'react-redux';
import { userSelector } from '../../features/users/userSlice';

/**
 * @param {{collapsed?: boolean, onCollapseChange?: ((isCollapsed: boolean) => void)? }} props
 */
export const SideBar = ({ collapsed = false, onCollapseChange = null }) => {
  const user = useSelector(userSelector);

  function toggleCollapsed() {
    if (onCollapseChange) {
      onCollapseChange(!collapsed);
    } else {
      collapsed = !collapsed;
    }
  }

  return (
    <ul
      className={'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ' + (collapsed ? 'collapse' : '')}
      id="accordionSidebar"
    >
      {/* Sidebar - Brand */}
      <a
        href={process.env.REACT_APP_STUDENTS_APP_URL}
        className="sidebar-brand d-flex align-items-center justify-content-center mb-3"
      >
        <div className="dropdown-list-image">
          <img className="w-50" src={logo} alt="..."></img>
          <div className="status-indicator bg-success"></div>
        </div>
      </a>

      {/* Sidebar Toggler (Sidebar) */}
      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle" onClick={toggleCollapsed}></button>
      </div>

      <div className="sidebar">
        {sidebarItems.map((item) => {
          if (item.roles && !item.roles.includes(user.role)) {
            return null;
          }
          return (
            <div className="sidebar-card d-lg-flex" key={item.link || item.externalLink}>
              {item.imgSrc ? (
                <img className={item.cssClass + ' sidebar-card-illustration mb-2'} src={item.imgSrc} alt={item.alt} />
              ) : (
                ''
              )}
              {item.text ? (
                <p className="text-center mb-2">
                  <strong>{item.text}</strong>
                </p>
              ) : (
                ''
              )}
              {!item.link ? (
                <a className="btn btn-success btn-sm" href={item.externalLink}>
                  {item.linkText}
                </a>
              ) : (
                <Link className="btn btn-success btn-sm" to={item.link}>
                  {item.linkText}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </ul>
  );
};
