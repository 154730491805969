import { createSlice } from "@reduxjs/toolkit";
import { updateStudentInformation } from "./studentThunks";

const initialState = {
  isLoading: false,
  success: false,
  error: null
};

export const studentSlice = createSlice({
  name: 'tutor',
  initialState,
  reducers: {
    resetStudentState: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(updateStudentInformation.pending, (state, _action) => {
        state.isLoading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateStudentInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(updateStudentInformation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? null;
        state.success = false;
      });
  }
});

export const studentSuccessSelector = (state) => state.success;
export const studentErrorMessageSelector = (state) => state.error;
