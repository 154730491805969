import { createSlice } from '@reduxjs/toolkit';
import { fetchTutorshipStatus } from './tutorshipStatusThunk';

const initialState = {
  /** @type {boolean} */
  isLoading: false,

  /** @type {string | null} */
  error: null,

  /** @type {any?} */
  data: null,
};

export const tutorshipStatusSlice = createSlice({
  name: 'tutorshipStatus',
  initialState,
  reducers: {
    resetTutorState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTutorshipStatus.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTutorshipStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchTutorshipStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const tutorshipStatusSelector = (state) => state.tutorshipStatus.data;

export default tutorshipStatusSlice.reducer;
