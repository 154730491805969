import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../../features/common/alertService';
import { useNavigate } from 'react-router-dom';
import './PasswordRecovery.scss';
import { userErrorSelector, userSelector } from '../userSlice';
import Logo from '../../../assets/img/logo.png';
import { sendEmailToRecoverPassword } from '../userThunks';
import ReCAPTCHA from 'react-google-recaptcha';

const RegisterPassword = () => {
  const EMAIL_PATTERN = /^[\w-.]+@([\w-]+\.)+[\w]{2,4}$/;
  const [email, setEmail] = useState('');

  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const userError = useSelector(userErrorSelector);

  /** @type {React.MutableRefObject<ReCAPTCHA|null>} */
  const captchaRef = useRef(null);

  /** @type {[boolean | null, function]} */
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);

  const verifyCaptcha = () => {
    if (captchaRef.current?.getValue()) {
      setVerifiedCaptcha(true);
    }
  };

  const sendRecoverPasswordEmail = () => {
    if (!email || !EMAIL_PATTERN.test(email)) {
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor ingresa un correo electrónico válido',
      });
      return;
    }

    if (captchaRef.current?.getValue()) {
      setVerifiedCaptcha(true);
      dispatch(sendEmailToRecoverPassword(email));
    } else {
      setVerifiedCaptcha(false);
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: 'Realiza la validación Captcha',
      });
    }
  };

  useEffect(() => {
    if (userError) {
      showAlert({
        icon: 'success',
        title: 'Éxito',
        text: 'Hemos enviado un correo para que puedas recuperar tu contraseña',
      }).then(() => {
        window.location.href = '/';
      });
    }
  }, [navigate, userError]);

  useEffect(() => {
    if (user) {
      showAlert({
        icon: 'success',
        title: 'Éxito',
        text: 'Hemos enviado un correo para que puedas recuperar tu contraseña',
      }).then(() => {
        window.location.href = '/';
      });
    }
  }, [navigate, user]);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <img className="logo-login" src={Logo} alt="Logo" />
                      <h1 className="h4 text-gray-900 mb-2">Recuperar contraseña</h1>
                      <p className="mb-4">
                        Enviaremos un email al correo electrónico con el que ingresas a la plataforma para realizar el
                        cambio de contraseña
                      </p>
                    </div>

                    <p className="message-password"></p>
                    <div className="form-group">
                      <label htmlFor="">Correo electrónico</label>
                      <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="form-control form-control-user"
                        placeholder="Ingresa el correo con el que accedes a la plataforma"
                      />
                    </div>
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={verifyCaptcha}
                    />
                    <br />
                    <button onClick={sendRecoverPasswordEmail} className="btn btn-primary btn-user btn-block">
                      Enviar
                    </button>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPassword;
