// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics-dashboard-main-container .year-select {
  max-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/features/statistics/tutorshipStatistics/TutorshipStatistics.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR","sourcesContent":[".statistics-dashboard-main-container {\n    .year-select {\n        max-width: 150px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
