import React, { useEffect, useState } from 'react';

import { getColors } from '../../../../utils/colors';
import Chart from './Chart';

import './SuccessfulTutorships.scss';

const SuccessfulTutorships = ({ total, data }) => {
  /** @type {[string[], Function]} */
  const [colors, setColors] = useState([]);

  useEffect(() => {
    setColors(getColors(Object.keys(data).length));
  }, [data]);

  return (
    <div className="successful-tutorships-main-container card shadow mb-4 text-gray-900">
      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-center">
        <h5 className="m-0 font-weight-bold">Número de tutorías exitosas</h5>
      </div>
      {/* Card Body */}
      <div className="card-body">
        {!data || Object.keys(data || {}).length <= 0 ? (
          <h5 className="text-center">No tenemos datos para este apartado</h5>
        ) : (
          <div className="row justify-content-center h-100">
            <div className="col-12 h-100 row">
              <div className="col-12 text-center mb-4">
                <span className="font-weight-bold">Total:</span> {total}
              </div>
              <div className="col-12 row w-100" style={{ height: '80%' }}>
                <div className="col-md-6 h-100">
                  <div className="chart-pie pt-4 pb-2">
                    <Chart
                      subjects={Object.keys(data)}
                      numberOfSuccessfulTutorships={Object.values(data).map(
                        (subjectData) => subjectData.numberOfSuccessfulTutorships
                      )}
                      colors={colors}
                    />
                  </div>
                </div>
                <div className="table-container col-md-6 h-100">
                  <table
                    className="table table-bordered dataTable text-gray-900"
                    id="dataTable"
                    cellSpacing="0"
                    role="grid"
                    aria-describedby="dataTable_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting" tabIndex={0} aria-controls="dataTable" rowSpan={1} colSpan={1}>
                          Materia
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="dataTable" rowSpan={1} colSpan={1}>
                          N° de tutorías completadas
                        </th>
                        <th className="sorting" tabIndex={0} aria-controls="dataTable" rowSpan={1} colSpan={1}>
                          N° de asistentes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(data).map(([subject, info], i) => (
                        <tr key={i}>
                          <td>{subject}</td>
                          <td>{info.numberOfSuccessfulTutorships}</td>
                          <td>{info.numberOfStudents}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessfulTutorships;
