import axios from 'axios';

export const getTutorsAvailableForStudyGroups = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/studyGroup/tutor/getAllActive`);

    return response.data;
  } catch (err) {
    throw err.response.data.error;
  }
};

export const getTutors = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/tutor`);

    return response.data;
  } catch (err) {
    throw err.response.data.error;
  }
};

export const getAllTutorInfoById = async (tutorId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/tutor/allInfo/${tutorId}`);
    return response.data;
  } catch (err) {
    throw err.response.data.error;
  }
};

export const saveStudyGroupTutorRequest = async (tutorData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/studyGroup/tutor/`, tutorData);

    return response.data;
  } catch (err) {
    throw err.response.data.error;
  }
};

export const createOrUpdateTutor = async (newTutorData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/tutor/createOrUpdate`, newTutorData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const patchTutor = async (newTutorData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_DOMAIN}/api/v1/tutor`, newTutorData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const removeTutorById = async (tutorId) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_DOMAIN}/api/v1/tutor/${tutorId}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
