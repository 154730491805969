import React from 'react';

export const RatingSelect = ({ value, onChange, id }) => {
  const ratings = [
    { name: 'Selecciona una opción', value: '' },
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
  ];

  return (
    <select id={id} className="form-control" value={value} onChange={onChange}>
      {ratings.map((rating) => (
        <option value={rating.value} key={rating.value}>
          {rating.name}
        </option>
      ))}
    </select>
  );
};
