import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetStudyGroupState,
  studyGroupErrorSelector,
  studyGroupRequestsSelector,
  createdStudyGroupSelector,
} from '../studyGroupSlice';
import { resetTutorState, tutorsAvailableForStudyGroupsSelector } from '../../tutors/tutorSlice';
import { addNewStudyGroup, fetchStudyGroupRequests } from '../studyGroupThunks';
import { fetchTutorsAvailableForStudyGroups } from '../../tutors/tutorThunks';

const CreateStudyGroup = () => {
  const sessionShifts = [
    { id: 1, name: 'Mañana' },
    { id: 2, name: 'Tarde' },
    { id: 3, name: 'Noche' },
  ];

  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();

  /** @type {any?} */
  const createdStudyGroup = useSelector(createdStudyGroupSelector);

  /** @type {string?} */
  const studyGroupError = useSelector(studyGroupErrorSelector);

  /** @type {any[]?} */
  const studyGroupRequests = useSelector(studyGroupRequestsSelector);

  /** @type {any[]?} */
  const availableTutors = useSelector(tutorsAvailableForStudyGroupsSelector);

  const [studyGroup, setStudyGroup] = useState({
    /** @type {string?} */
    sessionName: null,
    /** @type {string?} */
    scheduledTime: null,
    /** @type {number?} */
    subjectId: null,
    /** @type {number?} */
    sessionShiftId: null,
    /** @type {number?} */
    tutorId: null,
    /** @type {number[]?} */
    studentIds: [],
  });

  /** @type {[any[], function]} */
  const [subjects, setSubjects] = useState([]);

  /** @type {[any[], function]} */
  const [filteredRequests, setFilteredRequests] = useState([]);

  /** @type {[any[], function]} */
  const [selectedStudents, setSelectedStudents] = useState([]);

  /** @type {[any, function]} */
  const [selectedSubject, setSelectedSubject] = useState(null);

  /** @type {[any, function]} */
  const [selectedSessionShift, setSelectedSessionShift] = useState(null);

  /** @type {[any, function]} */
  const [selectedTutor, setSelectedTutor] = useState(null);

  /** @type {[boolean, function]} */
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (!availableTutors) {
      dispatch(fetchTutorsAvailableForStudyGroups());
    }

    if (!studyGroupRequests) {
      dispatch(fetchStudyGroupRequests());
      return;
    }

    filterRequestsBySelectedData();

    // Se adquieren las materias directamente de las solicitudes (requests) de grupo de estudio.
    // La clase Map se asegura de que no se repitan los mismos valores en base a un id, que en este caso es subject.name
    // TODO: Usar el id de la materia en vez del nombre
    setSubjects([...new Map(studyGroupRequests.map((request) => [request.subject.name, request.subject])).values()]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableTutors, studyGroupRequests]);

  useEffect(() => {
    if (!isFormSubmitted) {
      return;
    }

    if (studyGroupError) {
      alert(studyGroupError);
    }

    if (createdStudyGroup) {
      alert('El grupo de estudio se creó exitosamente con id ' + createdStudyGroup.id);
      resetComponent();
      // TODO: Eliminar peticiones de grupo de estudio ya cumplidas
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitted, studyGroupError, createdStudyGroup]);

  useEffect(() => {
    filterRequestsBySelectedData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyGroup]);

  const filterRequestsBySelectedData = () => {
    if (!studyGroupRequests) {
      return;
    }

    if (!studyGroup.subjectId && !studyGroup.sessionShiftId) {
      setFilteredRequests(studyGroupRequests);
      return;
    }

    setFilteredRequests(
      studyGroupRequests.filter((request) => {
        let isValid = true;
        if (studyGroup.subjectId) {
          isValid = isValid && request.subjectId === studyGroup.subjectId;
        }
        if (studyGroup.sessionShiftId) {
          isValid = isValid && request.sessionShiftId === studyGroup.sessionShiftId;
        }

        return isValid;
      })
    );
  };

  if (!studyGroupRequests || !availableTutors) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-secondary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const resetComponent = () => {
    setStudyGroup({
      sessionName: null,
      scheduledTime: null,
      subjectId: null,
      sessionShiftId: null,
      tutorId: null,
      studentIds: [],
    });
    setSubjects([]);
    setFilteredRequests([]);
    setSelectedStudents([]);
    setSelectedSubject(null);
    setSelectedSessionShift(null);
    setSelectedTutor(null);
    setFormSubmitted(false);
    dispatch(resetStudyGroupState());
    dispatch(resetTutorState());
  };

  const renderStudentFullName = (student) => {
    return `${student?.name}  ${student?.firstLastName} ${student?.secondLastName}`;
  };

  const selectSubject = (subjectId) => {
    setStudyGroup({ ...studyGroup, subjectId });
    setSelectedSubject(subjects.find((subject) => subject.id === parseInt(subjectId)));
  };

  const selectSessionShift = (sessionShiftId) => {
    setStudyGroup({ ...studyGroup, sessionShiftId });
    setSelectedSessionShift(sessionShifts.find((sessionShift) => sessionShift.id === parseInt(sessionShiftId)));
  };

  const selectTutor = (tutorId) => {
    setStudyGroup({ ...studyGroup, tutorId });
    setSelectedTutor(availableTutors.find((tutor) => tutor.id === parseInt(tutorId)));
  };

  const toggleStudentInscription = (newStudent) => {
    if (selectedStudents.some((student) => student.id === newStudent.id)) {
      const studentsWithoutTheNewStudent = selectedStudents.filter((student) => student.id !== newStudent.id);

      setSelectedStudents(studentsWithoutTheNewStudent);
    } else {
      setSelectedStudents([...selectedStudents, newStudent]);
    }
  };

  const validateAndCreate = async (event) => {
    event.preventDefault();

    if (selectedStudents.length === 0) {
      // TODO: Usar sweet alert en vez de alert
      alert('Por favor, selecciona al menos un estudiante');
      return;
    }

    let studyGroupToCreate = {
      ...studyGroup,
      studentIds: selectedStudents.map((student) => student.id),
    };

    dispatch(addNewStudyGroup(studyGroupToCreate));

    setFormSubmitted(true);
  };

  return (
    <>
      {/* Page Heading */}
      <h1 className="h3 mb-2 text-gray-800">Crear grupos de estudio</h1>
      <p className="mb-4">
        A continuación seleccione los estudiantes que conformarán el grupo de estudio que desee crear{' '}
      </p>

      <form onSubmit={validateAndCreate}>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Nombre y hora de la sesión</h6>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="sessionName" className="form-label">
                  Nombre descriptivo
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sessionName"
                  placeholder="Ej: Sesión para Física I"
                  value={studyGroup?.sessionName || ''}
                  onChange={(e) => setStudyGroup({ ...studyGroup, sessionName: e.target.value })}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="scheduledTime" className="form-label">
                  Hora de inicio
                </label>
                <input
                  type="time"
                  className="form-control"
                  id="scheduledTime"
                  placeholder="Selecciona la hora"
                  value={studyGroup?.scheduledTime || ''}
                  onChange={(e) => setStudyGroup({ ...studyGroup, scheduledTime: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Elegir materia, jornada y tutor</h6>
          </div>
          <div className="card-body">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="materia" className="form-label">
                    Selecciona materia
                  </label>
                  <select
                    className="select-css"
                    id="materia"
                    value={studyGroup?.subjectId || ''}
                    onChange={(event) => selectSubject(parseInt(event.target.value))}
                    required
                  >
                    <option disabled value={''}>
                      Seleccionar...
                    </option>
                    {subjects.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="sessionShift" className="form-label">
                    Selecciona jornada
                  </label>
                  <select
                    className="select-css"
                    id="sessionShift"
                    value={studyGroup?.sessionShiftId || ''}
                    onChange={(e) => selectSessionShift(parseInt(e.target.value))}
                    required
                  >
                    <option disabled value={''}>
                      Seleccionar...
                    </option>
                    {sessionShifts.map((sessionShift) => (
                      <option key={sessionShift.id} value={sessionShift.id}>
                        {sessionShift.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="tutor" className="form-label">
                    Asigna el tutor
                  </label>
                  <select
                    className="select-css"
                    id="tutor"
                    value={studyGroup?.tutorId || ''}
                    onChange={(e) => selectTutor(parseInt(e.target.value))}
                    required
                  >
                    <option disabled value={''}>
                      Seleccionar...
                    </option>
                    {availableTutors.map((tutor) => (
                      <option key={tutor.id} value={tutor.id}>
                        {tutor.user.fullName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Elegir estudiantes</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Materia</th>
                    <th>Jornada</th>
                    <th>Seleccionar</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Materia</th>
                    <th>Jornada</th>
                    <th>Seleccionar</th>
                  </tr>
                </tfoot>
                <tbody>
                  {filteredRequests.map((request) => (
                    <tr key={request.id}>
                      <td>{renderStudentFullName(request.student)}</td>
                      <td>{request.subject?.name}</td>
                      <td>{request.sessionShift?.name}</td>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`student-${request.student.id}`}
                          checked={selectedStudents.some((student) => student.id === request.student.id)}
                          onChange={() => toggleStudentInscription(request.student)}
                          aria-label={`Select ${request.student?.name}`}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Tu información ha quedado así:</h6>
          </div>
          <div className="card-body">
            <div>
              <strong>Materia: </strong>
              <span>{selectedSubject?.name}</span>
            </div>
            <div>
              <strong>Jornada de la sesión: </strong>
              <span>{selectedSessionShift?.name}</span>
            </div>
            <div>
              <strong>Tutor asignado: </strong>
              <span>{selectedTutor?.user.fullName}</span>
            </div>
            <div>
              <strong>Cantidad de estudiantes: </strong>
              <span>{selectedStudents.length}</span> estudiantes.
            </div>
          </div>
        </div>
        <button className="btn btn-success btn-icon-split float-md-right">
          <span className="icon text-white-50">
            <i className="fas fa-check"></i>
          </span>
          <span className="text">Enviar</span>
        </button>
      </form>
    </>
  );
};

export default CreateStudyGroup;
