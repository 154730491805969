import React, { useEffect, useRef } from 'react';

export const ScrollToTopButton = () => {
  /** @type {import("react").MutableRefObject<HTMLAnchorElement?>} */
  const scrollToTopButton = useRef(null);

  useEffect(() => {
    window.removeEventListener('scroll', showOrHideOnScroll);
    window.addEventListener('scroll', showOrHideOnScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', showOrHideOnScroll);
    };
  }, []);

  // Scroll to top button appear
  function showOrHideOnScroll() {
    if (scrollToTopButton.current === null) {
      return;
    }
    const scrollDistance = window.scrollY;
    if (scrollDistance > 100) {
      scrollToTopButton.current.style.display = 'block';
    } else {
      scrollToTopButton.current.style.display = 'none';
    }
  }

  return (
    <a className="scroll-to-top rounded" href="#root" ref={scrollToTopButton}>
      <i className="fas fa-angle-up"></i>
    </a>
  );
};
