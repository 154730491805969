import axios from 'axios';

export const createTutorToStudentRating = async (tutorToStudentRatingData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/api/v1/tutorship/rating/tutorToStudent`,
      tutorToStudentRatingData
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
