import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { addNewStudyGroup, fetchStudyGroupRequests } from './studyGroupThunks';

const initialState = {
  isLoading: false,
  /** @type {string | null} */
  error: null,
  /** @type {any[]?} */
  requests: null,
  /** @type {boolean?} */
  lastCreated: null
};

export const studyGroupSlice = createSlice({
  name: 'studyGroup',
  initialState,
  reducers: {
    resetStudyGroupState: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStudyGroupRequests.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchStudyGroupRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requests = action.payload;
      })
      .addCase(addNewStudyGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewStudyGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lastCreated = action.payload;
      })
      .addMatcher(
        isAnyOf(
          fetchStudyGroupRequests.rejected,
          addNewStudyGroup.rejected
        ),
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message || 'Process failed';
        }
      );
  }
});

export const { resetStudyGroupState } = studyGroupSlice.actions;

export const studyGroupRequestsSelector = (state) => state.studyGroup.requests;
export const createdStudyGroupSelector = (state) => state.studyGroup.lastCreated;
export const studyGroupErrorSelector = (state) => state.studyGroup.error;

export default studyGroupSlice.reducer;
