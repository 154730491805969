import axios from 'axios';

export const getCareersByUniversityId = async (universityId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/v1/career/getByUniversity/${universityId}`
    );
    return response.data.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
