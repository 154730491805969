import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const Chart = ({ subjects, numberOfSuccessfulTutorships, colors }) => (
  <Pie
    data={{
      labels: subjects,
      datasets: [
        {
          data: numberOfSuccessfulTutorships,
          backgroundColor: colors,
        },
      ],
    }}
    options={{
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
      },
    }}
  />
);

export default Chart;
