/**
* Applies thousands and decimals format to a number
* @example
* // returns '1 234,56'
* formatNumber(1234.56, 2, ',', ' ');
*/
export function formatNumberUsingThousands(number, decimals, decimalPointSeparator='.', thousandsSeparator=',') {
   number = (number + '').replace(',', '').replace(' ', '');
   let num = !isFinite(+number) ? 0 : +number;
   let numberOfDecimals = !isFinite(+decimals) ? 0 : Math.abs(decimals);
   let formattedNumber = '';
   let toFixedFix = function (n, prec) {
       let k = Math.pow(10, prec);
       return '' + Math.round(n * k) / k;
   };
 
   formattedNumber = (numberOfDecimals ? toFixedFix(num, numberOfDecimals) : '' + Math.round(num)).split('.');
   if (formattedNumber[0].length > 3) {
       // Groups digits of the number in groups of 3 (regex) and separates them using the thousandsSeparator
       formattedNumber[0] = formattedNumber[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSeparator);
   }
   if ((formattedNumber[1] || '').length < numberOfDecimals) {
       formattedNumber[1] = formattedNumber[1] || '';
       formattedNumber[1] += new Array(numberOfDecimals - formattedNumber[1].length + 1).join('0');
   }
   return formattedNumber.join(decimalPointSeparator);
}