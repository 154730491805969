// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-tutorship-history {
  display: flex;
  justify-content: space-between;
}

.header-message {
  text-align: center;
  background-color: #ffffff;
  padding: 15px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  color: #4e73df;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/features/tutorship/managment/TutorshipHistory.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,2CAAA;AACF","sourcesContent":[".header-tutorship-history {\n  display: flex;\n  justify-content: space-between;\n}\n\n.header-message {\n  text-align: center;\n  background-color: #ffffff;\n  padding: 15px 30px;\n  margin-bottom: 20px;\n  border-radius: 10px;\n  color: #4e73df;\n  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
