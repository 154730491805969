export const BLOCK_TIME_RANGES = [
  { label: '6am - 8am', start: 6, end: 8 },
  { label: '7am - 9am', start: 7, end: 9 },
  { label: '8am - 10am', start: 8, end: 10 },
  { label: '9am - 11am', start: 9, end: 11 },
  { label: '10am - 12am', start: 10, end: 12 },
  { label: '11am - 1pm', start: 11, end: 13 },
  { label: '12am - 2pm', start: 12, end: 14 },
  { label: '1pm - 3pm', start: 13, end: 15 },
  { label: '2pm - 4pm', start: 14, end: 16 },
  { label: '3pm - 5pm', start: 15, end: 17 },
  { label: '4pm - 6pm', start: 16, end: 18 },
  { label: '5pm - 7pm', start: 17, end: 19 },
  { label: '6pm - 8pm', start: 18, end: 20 },
  { label: '7pm - 9pm', start: 19, end: 21 },
  { label: '8pm - 10pm', start: 20, end: 22 },
];

export const WORK_DAYS_OF_THE_WEEK = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

/**
 * Expresión regular sencilla para validar número de teléfono:
 * 
 * \+[0-9]{1,2}[0-9]{10}: Un número de teléfono del formato "+<código> <teléfono>"
 * 
 * [0-9]{10}: Un número de teléfono sin incluir el "+<código>" en frente
 */
export const CELLPHONE_NUMBER_REGULAR_EXPRESSION = /(\+[0-9]{1,2}[0-9]{10})|[0-9]{10}/;

/*
* Regular expression that verifies if an email is an institutional email ending in .edu.co
*/
export const INSTITUTIONAL_EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+(\.edu\.co)/;
