import { createSlice } from '@reduxjs/toolkit';
import {
  fetchCurrentUser,
  logoutUser,
  createNewUser,
  login,
  registerPassword,
  updateUser,
  fetchUserById,
  sendEmailToRecoverPassword,
  updatePassword,
} from './userThunks';
import axios from 'axios';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    /** @type {boolean} */
    isLoading: false,

    /** @type {string | null} */
    error: null,

    /** @type {any?} */
    data: null,

    /** @type {any?} */
    allInfo: null,

    /** @type {string?} */
    redirectUrl: null,

    /** @type {string?} */
    successMessage: null,
  },
  reducers: {
    clearSuccessMessage: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.redirectUrl = action.payload.redirectUrl;

        localStorage.setItem('jwt-atv', action.payload.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;

        localStorage.removeItem('jwt-atv');
      })

      .addCase(logoutUser.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.redirectUrl = null;
        localStorage.removeItem('jwt-atv');
        axios.defaults.headers.common['Authorization'] = '';
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchCurrentUser.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(createNewUser.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = action.payload.message;
      })
      .addCase(createNewUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(updateUser.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = action.payload.message;
      })
      .addCase(
        updateUser.rejected,
        /** @param {any} action */ (state, action) => {
          state.isLoading = false;
          state.error = action.payload.data.error || null;
        }
      )

      .addCase(registerPassword.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(registerPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(updatePassword.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(sendEmailToRecoverPassword.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendEmailToRecoverPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(sendEmailToRecoverPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchUserById.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allInfo = action.payload.data;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const { clearSuccessMessage } = userSlice.actions;

export const userSelector = (state) => state.user.data;
export const userAllInfoSelector = (state) => state.user.allInfo;
export const userErrorSelector = (state) => state.user.error;
export const userSuccessSelector = (state) => state.user.successMessage;
export const redirectUrlSelector = (state) => state.user.redirectUrl;

export default userSlice.reducer;
