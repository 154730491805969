import axios from 'axios';

export const getUniversities = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/university/`);
    return response.data.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
