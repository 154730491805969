import { createSlice } from '@reduxjs/toolkit';
import { fetchTutorshipById, fetchTutorshipHistory, updateTutorshipData } from './tutorshipThunks';

const initialState = {
  isLoading: false,
  /** @type {string | null} */
  error: null,
  /** @type {any?} */
  data: null,
  /** @type {any?} */
  currentOne: null,
  /** @type {any?} */
  tutorshipHistory: null,
};

export const tutorshipSlice = createSlice({
  name: 'tutorship',
  initialState,
  reducers: {
    cleanupUpdateTutorShip() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateTutorshipData.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateTutorshipData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(updateTutorshipData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchTutorshipById.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTutorshipById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentOne = action.payload.data;
      })
      .addCase(fetchTutorshipById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })

      .addCase(fetchTutorshipHistory.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTutorshipHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tutorshipHistory = action.payload;
      })
      .addCase(fetchTutorshipHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const tutorshipSelector = (state) => state.tutorship.data;
export const tutorshipErrorSelector = (state) => state.tutorship.error;
export const currentTutorshipSelector = (state) => state.tutorship.currentOne;
export const tutorshipHistorySelector = (state) => state.tutorship.tutorshipHistory;

export const { cleanupUpdateTutorShip } = tutorshipSlice.actions;