import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCareersByUniversityId } from './careerService';

export const fetchCareersByUniversityId = createAsyncThunk(
  'careers/byUniversityId',
  /** @param {number} universityId */
  async (universityId) => {
    return await getCareersByUniversityId(universityId);
  }
);
