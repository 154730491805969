import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { showAlert } from '../../common/alertService';
import { newTutorToStudentRating } from '../../tutorship/tutorToStudentRating/tutorToStudentRatingThunks';
import {
  TutorToStudentRatingSelector,
  TutorToStudentRatingErrorSelector,
  cleanUpRatingTutorship,
} from '../tutorToStudentRating/tutorToStudentRatingSlice';
import { RatingSelect } from '../../common/rating/RatingSelect';
import { userSelector } from '../../users/userSlice';
import { Roles } from '../../users/roles/roles';

const EvaluationTutorToStudent = () => {
  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();

  const [studentAttitudeRating, setStudentAttitudeRating] = useState('');
  const [studentPunctualityRating, setStudentPunctualityRating] = useState('');
  const [comments, setComments] = useState('');

  const { tutorshipId } = useParams();
  const user = useSelector(userSelector);
  const successMessage = useSelector(TutorToStudentRatingSelector);
  const errorMessage = useSelector(TutorToStudentRatingErrorSelector);

  useEffect(() => {
    dispatch(cleanUpRatingTutorship());

    return () => {
      dispatch(cleanUpRatingTutorship());
    };
  }, [dispatch]);

  function onStudentDisposition(event) {
    const selectedValue = event.target.value;
    setStudentAttitudeRating(selectedValue);
  }

  function onPunctualityStudent(event) {
    const selectedValue = event.target.value;
    setStudentPunctualityRating(selectedValue);
  }

  const sendEvaluationTutorToStudent = (event) => {
    event.preventDefault();

    if (studentAttitudeRating === '' || studentPunctualityRating === '') {
      showAlert({ icon: 'error', title: 'Oops...', text: 'Debes completar los campos obligatorios' });
      return;
    }

    const ratingData = {
      tutorshipId,
      studentAttitudeRating,
      studentPunctualityRating,
      comments,
    };

    dispatch(newTutorToStudentRating(ratingData));
  };

  useEffect(() => {
    if (successMessage) {
      showAlert({
        icon: 'success',
        title: 'Éxito',
        text: 'Valoración a estudiante exitosa',
      }).then(() => {
        dispatch(cleanUpRatingTutorship());
        window.location.href = '/tutorias/lista';
      });
    }
    if (errorMessage) {
      showAlert({ icon: 'error', title: 'Oops...', text: errorMessage });
    }
  }, [dispatch, successMessage, errorMessage]);

  return (
    <React.Fragment>
      <section className="d-flex justify-content-center">
        <form className="w-75 mx-auto p-4 mt-4 rounded shadow bg-white" onSubmit={sendEvaluationTutorToStudent}>
          <h1 className="title mb-4" id="editTutorshipTitle">
            Valorar Estudiante
          </h1>
          {user.role === Roles.TUTOR && (
            <h6 className="header-message">
              Tu valoración nos importa, cuéntanos cómo te pareció el espacio con el estudiante,
              <br />
              siendo 5 un espacio muy enriquecedor y 1 un espacio que puede mejorar.
            </h6>
          )}
          <div className="form-group">
            <label htmlFor="studentDispositionRating">Disposición del estudiante durante la tutoría *</label>
            <RatingSelect id="studentDispositionRating" value={studentAttitudeRating} onChange={onStudentDisposition} />
          </div>
          <div className="form-group">
            <label htmlFor="studentPunctualityRating">Puntualidad del estudiante *</label>
            <RatingSelect
              id="studentPunctualityRating"
              value={studentPunctualityRating}
              onChange={onPunctualityStudent}
            />
          </div>

          <div className="form-group">
            <label htmlFor="commentTutorToStudentRating">Comentarios adicionales</label>
            <textarea
              className="form-control"
              id="commentTutorToStudentRating"
              placeholder="Escribe aquí tu comentario"
              onChange={(e) => setComments(e.target.value)}
            ></textarea>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <button type="submit" className="btn btn-success btn-ms shadow-sm">
              Guardar valoración
            </button>
          </div>
        </form>
      </section>
      <div className="d-flex justify-content-end w-75 mx-auto">
        <Link to="/tutorias/lista" className="btn mt-3 shadow-sm bg-white">
          Volver
        </Link>
      </div>
    </React.Fragment>
  );
};

export default EvaluationTutorToStudent;
