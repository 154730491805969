import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../../features/common/alertService';
import { updatePassword } from '../userThunks';
import { useLocation, useNavigate } from 'react-router-dom';
import './RegisterPassword.css';
import { userErrorSelector, userSelector } from '../userSlice';
import Logo from '../../../assets/img/logo.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RegisterPassword = () => {
  const [token, setToken] = useState('');
  const query = useQuery();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const userError = useSelector(userErrorSelector);
  const newPasswordElement = createRef();
  const confirmPasswordElement = createRef();
  const ocultarPasswordElement = createRef();

  let passToggle = () => {
    if (newPasswordElement.current.type === 'password' && confirmPasswordElement.current.type === 'password') {
      newPasswordElement.current.type = 'text';
      confirmPasswordElement.current.type = 'text';
      ocultarPasswordElement.current.innerHTML = 'Ocultar contraseña';
    } else {
      newPasswordElement.current.type = 'password';
      confirmPasswordElement.current.type = 'password';
      ocultarPasswordElement.current.innerHTML = 'Mostrar contraseña';
    }
  };

  useEffect(() => {
    const tokenValue = query.get('token');
    if (tokenValue) {
      setToken(tokenValue);
    } else {
      window.close();
    }
  }, [query, navigate]);

  const sendUpdatePassword = (e) => {
    e.preventDefault();

    if (password === '' && confirmPassword === '') {
      return showAlert({ icon: 'error', title: 'Oops...', text: 'Ingresa la nueva contraseña.' });
    }
    if (password.length < 8 && confirmPassword.length < 8) {
      return showAlert({ icon: 'error', title: 'Oops...', text: 'Debe tener al menos 8 caracteres.' });
    }
    if (password.length !== confirmPassword.length) {
      return showAlert({ icon: 'error', title: 'Oops...', text: 'Las contraseñas son diferentes.' });
    }
    if (password === confirmPassword) {
      const sendData = {
        password: password,
        token: token,
      };
      return dispatch(updatePassword(sendData));
    }
  };

  useEffect(() => {
    if (user) {
      showAlert({
        icon: 'success',
        title: 'Éxito',
        text: 'La contraseña ha sido modificada con éxito, puedes iniciar sesión',
      }).then(() => {
        window.location.href = '/';
      });
    }
  }, [navigate, user]);

  useEffect(() => {
    if (userError) {
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: userError,
      });
    }
  }, [dispatch, navigate, user, userError]);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <img className="logo-login" src={Logo} alt="Logo" />
                      <h1 className="h4 text-gray-900 mb-2">Cambiar contraseña</h1>
                      <p className="mb-4">
                        Por tu seguridad, la contraseña debe ser diferente a tu número de documento.
                      </p>
                    </div>

                    <p className="message-password"></p>
                    <div className="form-group">
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        ref={newPasswordElement}
                        className="form-control form-control-user"
                        placeholder="Nueva contraseña"
                      />
                      <p className="small">Debe tener al menos 8 caracteres</p>
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        id="confirm-password-input"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        ref={confirmPasswordElement}
                        className="form-control form-control-user"
                        placeholder="Repetir contraseña"
                      />
                      <label>
                        <input type="checkbox" onClick={passToggle} value="text" ref={ocultarPasswordElement} />
                        Mostrar contraseña
                      </label>
                    </div>
                    <button onClick={sendUpdatePassword} className="btn btn-primary btn-user btn-block">
                      Enviar
                    </button>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPassword;
