import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCurrentUser,
  loginRequest,
  logOutRequest,
  registerNewUser,
  setPassword,
  updateUserInformation,
  getUserById,
  emailTorecoverPassword,
  setUpdatePassword,
} from './userService';

export const login = createAsyncThunk(
  'users/login',
  /** @param {{email: string, password: string}} loginData */
  async (loginData) => {
    return await loginRequest(loginData);
  }
);

export const logoutUser = createAsyncThunk('users/logout', async () => {
  return await logOutRequest();
});

export const fetchCurrentUser = createAsyncThunk('users/fetchCurrent', async () => {
  return await getCurrentUser();
});

export const registerPassword = createAsyncThunk(
  'users/registerPassword',
  /** @param {{email: string, password: string}} passwordData */
  async (passwordData) => {
    return await setPassword(passwordData);
  }
);

export const updatePassword = createAsyncThunk(
  'users/updatePassword',
  /** @param {{token: string, password: string}} passwordData */
  async (passwordData) => {
    return await setUpdatePassword(passwordData);
  }
);

export const sendEmailToRecoverPassword = createAsyncThunk(
  'users/recoverPassword',
  /** @param {any} userEmail */
  async (userEmail) => {
    return await emailTorecoverPassword(userEmail);
  }
);

export const createNewUser = createAsyncThunk(
  'users/registerNewUser',
  /** @param {any} newUserData */
  async (newUserData) => {
    return await registerNewUser(newUserData);
  }
);

export const updateUser = createAsyncThunk(
  'users/',
  /** @param {any} newUserInformation */
  async (newUserInformation, { rejectWithValue }) => {
    try {
      return await updateUserInformation(newUserInformation);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchUserById = createAsyncThunk(
  'careers/userId',
  /** @param {number} userId */
  async (userId) => {
    return await getUserById(userId);
  }
);
