import axios from 'axios';

export const getTutorshipsReport = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/reports/tutorships`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
