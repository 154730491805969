import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMunicipalitiesByDepartmentId } from './municipalityService';

export const fetchMunicipalitiesByDepartmentId = createAsyncThunk(
  'municipalities/getByDepartmentId',
  /** @param {number} departmentId */
  async (departmentId) => {
    return await getMunicipalitiesByDepartmentId(departmentId);
  }
);
