import React, { useState, useEffect } from 'react';
import './StudyGroupRequestForm.css';
import Multiselect from 'multiselect-react-dropdown';

import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../users/userSlice';
import { addStudyGroupRequest, getFormData } from '../studyGroupRequestThunks';
import { formDataSelector, studyGroupRequestErrorSelector, studyGroupRequestSelector } from '../studyGroupRequestSlice';
import { showAlert } from '../../common/alertService';

const StudyGroupRequestForm = () => {
  const formData = useSelector(formDataSelector);
  const createdStudyGroupRequest = useSelector(studyGroupRequestSelector);
  const studyGroupRequestError = useSelector(studyGroupRequestErrorSelector);
  const user = useSelector(userSelector);

  /** @type {[any[], function]} */
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [studyGroupShiftId, setShift] = useState('');
  const [studyModalityId, setModality] = useState('');
  const [studentComment, setComment] = useState('');
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFormData());
    if (isFormSubmitted && studyGroupRequestError) {
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: studyGroupRequestError,
      });
    }
    if (isFormSubmitted && createdStudyGroupRequest) {
      showAlert({
        icon: 'success',
        title: 'Creación Exitosa',
        text: 'Se ha realizado la creación exitosamente',
      });
    }
  }, [createdStudyGroupRequest, dispatch, isFormSubmitted, studyGroupRequestError]);

  if (!formData) {
    return <div> ...Loading </div>;
  }

  const { studyGroupShift, semester, studyModality, universityHeadquarters, studyGroupId, subjects } =
    formData.formData;

  function onChangeSubjects(e) {
    let selectedSubjects = e;
    setSelectedSubjects(selectedSubjects);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    let studyGroupFormData = {
      studentId: user.studentId,
      subjectIds: selectedSubjects.map((subject) => subject.id),
      sessionShiftId: parseInt(studyGroupShiftId, 10),
      semesterId: parseInt(semester, 10),
      studyModalityId: parseInt(studyModalityId, 10),
      studentComment,
    };
    dispatch(addStudyGroupRequest(studyGroupFormData));
  };

  return (
    <div className="card shadow mb-4">
      {/* <!-- Card Header - Dropdown --> */}
      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 className="m-0 font-weight-bold text-primary">Grupos de estudio</h6>
        <div className="dropdown no-arrow"></div>
      </div>
      {/* <!-- Card Body --> */}
      <div className="card-body w-100">
        {/* <!-- chart-pie --> */}
        <div className="pt-4 pb-2">
          {createdStudyGroupRequest && (
            <p className="msgge" id="msggestatus">
              Su solicitud ha sido recibida y está siendo revisada. <br />
              Una vez se te asigne un grupo de estudio te avisaremos por este medio y en el correo institucional.
            </p>
          )}
          <p className="msgge" id="msgagregado" style={{ display: 'none' }}>
            Has sido asignado al grupo de estudio {studyGroupId}.
          </p>
          {!createdStudyGroupRequest && (
            <form id="gediv" onSubmit={handleSubmit}>
              <label htmlFor="options">
                ¿En qué materias requieres un grupo de estudio? * <br /> (Puedes seleccionar varias)
              </label>
              <Multiselect
                displayValue="name"
                onRemove={(e) => onChangeSubjects(e)}
                onSelect={(e) => onChangeSubjects(e)}
                className="select2"
                placeholder="Selecciona una o varias"
                emptyRecordMsg="No hay opciones para seleccionar"
                options={subjects}
              ></Multiselect>
              <label style={{ marginTop: '10px' }} htmlFor="options">
                ¿En qué jornada puedes participar? *
              </label>
              <select
                className="select-css"
                name="jornada"
                id="jornada"
                onChange={(e) => setShift(e.target.value)}
                required
              >
                <option value="eligeunaopcion">Elige una opción</option>
                {studyGroupShift.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.name}
                  </option>
                ))}
              </select>
              <label style={{ marginTop: '10px' }} htmlFor="options">
                ¿En qué modalidad prefieres el grupo de estudio? *
              </label>
              <select
                className="select-css"
                name="modalidad"
                id="modalidad"
                onChange={(e) => setModality(e.target.value)}
                required
              >
                <option value="eligeunaopcion">Elige una opción</option>
                {studyModality.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.name}
                  </option>
                ))}
              </select>
              <label style={{ marginTop: '10px' }} htmlFor="options">
                ¿Qué te motiva a ingresar a un grupo de estudio?
              </label>
              <textarea
                className="form-control"
                name="comentarioestudiante"
                placeholder="Escribe aquí tu respuesta"
                id=""
                rows={5}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
              <div className="mt-3">
                <button type="submit" className="btn btn-success btn-icon-split float-md-right">
                  <span className="icon text-white-50">
                    <i className="fas fa-check"></i>
                  </span>
                  <span className="text">Enviar</span>
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudyGroupRequestForm;
