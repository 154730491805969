export const getStudyGroupModality = async () => {
  try {
    // TODO: Realizar llamado a base de datos para solicitar modalidades
    const data = [
      { id: 1, modality: "Virtual" },
      { id: 2, modality: "Presencial" },
      { id: 3, modality: "Ambas" },
    ];
    return data;
  } catch (err) {
    throw err.response.data.error;
  }
};
