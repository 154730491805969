import { useLocation, Navigate, Outlet } from 'react-router-dom';

import React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../userSlice';

const Auth = ({ allowedRoles }) => {
  const user = useSelector(userSelector);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!allowedRoles.includes(user.role)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  
  return <Outlet />;
};

export default Auth;
