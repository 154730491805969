import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTutorToStudentRating } from './tutorToStudentRatingService';

export const newTutorToStudentRating = createAsyncThunk(
  'tutorship/rating/createTutorToStudentRating',
  /** @param {any} tutorToStudentRatingData */
  async (tutorToStudentRatingData) => {
    return await createTutorToStudentRating(tutorToStudentRatingData);
  }
);
