import { createSlice } from '@reduxjs/toolkit';
import { fetchDocumentsType } from './documentTypeThunk';

export const documentsTypeSlice = createSlice({
  name: 'documentType',
  initialState: {
    /** @type {boolean} */
    isLoading: false,

    /** @type {string | null} */
    error: null,

    /** @type {any?} */
    data: null,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentsType.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDocumentsType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchDocumentsType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const documentsTypeSelector = (state) => state.documentType.data;
