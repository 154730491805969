import { createAsyncThunk } from '@reduxjs/toolkit';
import { createStudyGroupRequest, fetchFormData } from './studyGroupRequestService';

export const addStudyGroupRequest = createAsyncThunk(
  'studyGroup/create',
  /** @param {any} studyGroupData */
  async (studyGroupData) => {
    return await createStudyGroupRequest(studyGroupData);
  }
);

export const getFormData = createAsyncThunk('studyGroup/getFormData', async () => {
  return await fetchFormData();
});
