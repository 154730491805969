// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subjects-menu-main-container {
  flex-wrap: wrap;
  overflow-x: auto;
}
.subjects-menu-main-container .subjects-menu-item {
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/features/statistics/components/QualitativeEvaluations/components/SubjectsMenu.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;AACE;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACN","sourcesContent":[".subjects-menu-main-container {\n  flex-wrap: wrap;\n  overflow-x: auto;\n\n  .subjects-menu-item {\n      min-width: 150px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
