import React from 'react';
import './CategoryCard.scss';

const CategoryCard = ({ icon, label, description, data, color }) => (
  <div className="category-card-main-container card col-md mx-2">
    <div className="card-header py-3 d-flex row align-items-center justify-content-center bg-none">
      <h5 className="m-0 font-weight-bold text-center">{label}</h5>
    </div>
    <div className="card-body justify-content-center">
      <div className="col-12 justify-content-center d-flex mb-4">
        <i className={`fa fa-${icon} fa-5x text-${color}`}></i>
      </div>

      <h4 className={`font-weight-bold text-center text-${color}`}>{data.value}</h4>
      <div className="progress">
        <div
          className={`progress-bar bg-${color}`}
          role="progressbar"
          style={{ width: data.percentage + '%' }}
          aria-valuenow={data.percentage}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
      <div className="text-center mt-3 h6" style={{ fontSize: '18px' }}>
        {description}
      </div>
    </div>
  </div>
);

export default CategoryCard;
