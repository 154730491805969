import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../features/users/userSlice';
import { Outlet, useNavigate } from 'react-router-dom';
import { ScrollToTopButton } from './ScrollToTopButton';
import { SideBar } from './SideBar';
import CryptoJS from 'crypto-js';
import { logoutUser } from '../../features/users/userThunks';
import { Roles } from '../../features/users/roles/roles';
import { showAlert } from '../../features/common/alertService';

const DashboardLayout = () => {
  const user = useSelector(userSelector);
  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSideBarCollapsed, setSideBarCollapsed] = useState(false);

  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
    }
  }, [user, navigate]);

  if (!user?.id) {
    // TODO: Create a loading component and render it here
    return <div>Cargando...</div>;
  }

  async function logout() {
    try {
      await dispatch(logoutUser()).unwrap();
      navigate('/login');
    } catch (err) {
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrió un error al tratar de cerrar sesión',
      });
    }
  }

  function gravatarHash(textToHash) {
    return textToHash ? CryptoJS.MD5(textToHash.trim().toLowerCase()) : '';
  }

  function redirectToEditTutor() {
    navigate(`/tutores/editar/${user.id}`);
  }

  return (
    <div id="wrapper">
      <SideBar collapsed={isSideBarCollapsed} onCollapseChange={setSideBarCollapsed}></SideBar>

      {/* Content Wrapper */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* Main Content */}
        <div id="content">
          {/* Topbar */}
          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            {/* Sidebar Toggle (Topbar) */}
            <button
              id="sidebarToggleTop"
              className={'btn btn-link rounded-circle mr-3' + (isSideBarCollapsed ? '' : ' d-md-none')}
              onClick={() => setSideBarCollapsed(!isSideBarCollapsed)}
            >
              <i className="fa fa-bars"></i>
            </button>

            <ul className="navbar-nav ml-auto">
              <div className="topbar-divider d-none d-sm-block"></div>

              {/* Nav Item - User Information */}
              <li className="nav-item dropdown no-arrow">
                <button
                  className="btn btn-link nav-link dropdown-toggle"
                  id="userDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div>
                    <strong className="mr-2 d-none text-dark d-lg-block">{`${user.fullName}`}</strong>
                    <span className="mr-2 d-none d-lg-block text-gray-600 small">{user.email}</span>
                  </div>
                  <img
                    className="img-profile rounded-circle"
                    src={`https://www.gravatar.com/avatar/${gravatarHash(user.email)}?d=robohash&s=100`}
                    alt="profile"
                  ></img>
                </button>
                {/* Dropdown - User Information */}
                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown"
                >
                  {user.role === Roles.TUTOR && (
                    <button className="dropdown-item" onClick={() => redirectToEditTutor()}>
                      <i className="fas fa-edit fa-sm fa-fw mr-2 text-gray-400"></i>
                      Editar perfil de tutor
                    </button>
                  )}
                  <button className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Cerrar sesión
                  </button>
                </div>
              </li>
            </ul>
          </nav>
          {/* End of Topbar */}

          {/* Begin Page Content */}
          <div className="container-fluid">
            <Outlet></Outlet>
          </div>

          <div
            className="modal fade"
            id="logoutModal"
            role="dialog"
            aria-labelledby="logoutModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="logoutModalLabel">
                    ¡Espera!
                  </h5>
                  <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">¿Seguro que deseas cerrar sesión?</div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" type="button" data-dismiss="modal">
                    No
                  </button>
                  <button className="btn btn-primary" onClick={logout} data-dismiss="modal">
                    Si
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="sticky-footer bg-white">
          <div className="container">
            <div className="copyright text-center">
              <span>Todos los derechos reservados &copy; Fundación Antivirus para la deserción 2024</span>
            </div>
          </div>
        </footer>

        <ScrollToTopButton></ScrollToTopButton>
      </div>
    </div>
  );
};

export default DashboardLayout;
