import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { showAlert } from '../common/alertService';

import { fetchUserById, updateUser } from '../users/userThunks';
import { fetchDocumentsType } from '../common/documentType/documentTypeThunk';
import { fetchDepartments } from '../location/departments/departmentThunk';
import { fetchGenders } from '../common/gender/genderThunk';
import { fetchMunicipalitiesByDepartmentId } from '../location/municipalities/municipalityThunk';
import { fetchUniversities } from '../universities/universityThunk';
import { fetchCareersByUniversityId } from '../universities/careers/careerThunk';

import { userAllInfoSelector, userSelector } from '../users/userSlice';
import { gendersSelector } from '../common/gender/genderSlice';
import { departmentsSelector } from '../location/departments/departmentSlice';
import { municipalitiesSelector } from '../location/municipalities/municipalitySlice';
import { documentsTypeSelector } from '../common/documentType/documentTypeSlice';
import { universitiesSelector } from '../universities/universitySlice';
import { careersSelector } from '../universities/careers/careerSlice';

import { studentErrorMessageSelector, studentSuccessSelector } from './studentSlice';

import { updateStudentInformation } from './studentThunks';

const StudentEditInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(userSelector);
  const userInfo = useSelector(userAllInfoSelector);
  const genders = useSelector(gendersSelector);
  const departments = useSelector(departmentsSelector);
  const municipalities = useSelector(municipalitiesSelector);
  const documentsType = useSelector(documentsTypeSelector);
  const universities = useSelector(universitiesSelector);
  const careers = useSelector(careersSelector);

  const errorMessage = useSelector(studentErrorMessageSelector);
  const success = useSelector(studentSuccessSelector);

  const { id } = useParams();
  const [documentTypeId, setDocumentTypeId] = useState();
  const [documentNumber, setDocumentNumber] = useState();
  const [municipalityId, setMunicipalityId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [name, setName] = useState();
  const [firstLastName, setFirstLastName] = useState();
  const [secondLastName, setSecondLastName] = useState();
  const [genderId, setGenderId] = useState();
  const [email, setEmail] = useState();
  const [institutionalEmail, setInstitutionalEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [cellphoneNumber, setCellphoneNumber] = useState();
  const [academicLevel, setAcademicLevel] = useState();
  const [universityId, setUniversityId] = useState();
  const [careerId, setCareerId] = useState();

  function updateuserinformation(event) {
    event.preventDefault();

    const userInformation = {
      id: id ? parseInt(id) : user.id,
      documentTypeId: documentTypeId ?? user.documentTypeId,
      documentNumber: documentNumber ?? user.documentNumber,
      municipalityId: municipalityId ?? user.municipalityId,
      departmentId: departmentId ?? user.departmentId,
      name: name ?? user.name,
      firstLastName: firstLastName ?? user.documentTypeId,
      secondLastName: secondLastName ?? user.secondLastName,
      genderId: genderId ?? user.genderId,
      email: email ?? user.email,
      institutionalEmail: institutionalEmail ?? user.institutionalEmail,
      phoneNumber: phoneNumber ?? user.phoneNumber,
      cellphoneNumber: cellphoneNumber ?? user.cellphoneNumber,
      academicLevel: academicLevel ?? user.academicLevel,

      universityId: universityId ?? user.universityId,
      careerId: careerId ?? user.careerId,
    };
    dispatch(updateUser(userInformation));
    dispatch(updateStudentInformation(userInformation));
  }

  useEffect(() => {
    if (departments === null) {
      dispatch(fetchDepartments());
    }
    if (genders === null) {
      dispatch(fetchGenders());
    }
    if (universities === null) {
      dispatch(fetchUniversities());
    }
    if (documentsType === null) {
      dispatch(fetchDocumentsType());
    }
  }, [departments, genders, universities, documentsType, dispatch]);

  useEffect(() => {
    const currentId = id ? parseInt(id) : user.id;
    dispatch(fetchUserById(currentId));
  }, [dispatch, id, user.id]);

  useEffect(() => {
    if (errorMessage) {
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
      }).then(() => {
        if (!userInfo) {
          navigate('/');
        }
      });
    }
    if (success) {
      showAlert({
        icon: 'success',
        title: 'Éxito',
        text: 'Los datos quedaron guardados en el sistema. Un miembro de FATV se comunicará contigo',
      }).then(function () {
        navigate('/');
      });
    }
  });

  function onDepartmentChange(event) {
    dispatch(fetchMunicipalitiesByDepartmentId(event.target.value));
    setDepartmentId(event.target.value);
  }

  function onUniversityChange(event) {
    dispatch(fetchCareersByUniversityId(event.target.value));
    setUniversityId(event.target.value);
  }

  if (!userInfo) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <div className="titleDetailsContainer mb-2">
        <h1 className="h3 mb-2 text-gray-800">Detalles del estudiante</h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header">
          <h6 className="font-weight-bold">Datos académicos</h6>
        </div>
        <div className="card-body">
          <div>
            <div className="row ">
              <div className="form-group col-12">
                <label htmlFor="acedemicLevel" className="form-label">
                  Nivel académico
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="academicLevel"
                  value={academicLevel || userInfo.studentData.academicLevel}
                  onChange={(e) => setAcademicLevel(e.target.value)}
                />
              </div>
              <div className="col-sm-6 mb-3 mb-sm-0">
                <label htmlFor="universityId">Universidad *</label>
                <select value={universityId} className="form-control " onChange={onUniversityChange} id="universityId">
                  <option value={userInfo.studentData.universityId}></option>
                  {universities?.map((university) => (
                    <option value={university.id} key={university.id}>
                      {university.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6">
                <label htmlFor="careerId">Carrera *</label>
                <select
                  value={careerId}
                  onChange={(event) => setCareerId(event.target.value)}
                  className="form-control "
                  id="careerId"
                >
                  <option></option>
                  {careers?.map((career) => (
                    <option value={career.id} key={career.id}>
                      {career.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form className="card shadow mb-4" onSubmit={updateuserinformation}>
        <div className="card-header">
          <h6 className="font-weight-bold">Datos personales</h6>
        </div>
        <div className="card-body">
          <div>
            <div className="row ">
              <div className="form-group col-12">
                <label htmlFor="documentTypeId">Tipo de documento *</label>
                <select
                  value={documentTypeId}
                  onChange={(event) => setDocumentTypeId(event.target.value)}
                  className="form-control "
                  id="documentTypeId"
                >
                  <option value=""></option>
                  {documentsType?.map((documentType) => (
                    <option value={documentType.id} key={documentType.id}>
                      {documentType.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-12">
                <label htmlFor="documentNumber" className="form-label">
                  Número de documento
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="documentNumber"
                  readOnly
                  value={user?.documentNumber}
                  onChange={(e) => setDocumentNumber(e.target.value)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="departmentId">Departamento de residencia *</label>
                <select value={departmentId} className="form-control " onChange={onDepartmentChange} id="departmentId">
                  <option></option>
                  {departments?.map((department) => (
                    <option value={department.id} key={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-12">
                <label htmlFor="municipalityId">Municipio donde vive *</label>
                <select
                  value={municipalityId}
                  onChange={(event) => setMunicipalityId(event.target.value)}
                  className="form-control "
                  id="municipalityId"
                >
                  <option></option>
                  {municipalities?.map((municipality) => (
                    <option value={municipality.id} key={municipality.id}>
                      {municipality.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-12">
                <label htmlFor="name" className="form-label">
                  Nombre(s)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name || userInfo.studentData.name || ''}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="firstLastname" className="form-label">
                  Primer apellido
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstLastname"
                  value={firstLastName || userInfo.studentData.firstLastName}
                  onChange={(e) => setFirstLastName(e.target.value)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="secondLastname" className="form-label">
                  Segundo apellido
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="secondLastname"
                  value={secondLastName || userInfo.studentData.secondLastName}
                  onChange={(e) => setSecondLastName(e.target.value)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="genderId">Género *</label>
                <select
                  value={genderId}
                  onChange={(event) => setGenderId(event.target.value)}
                  className="form-control "
                  id="genderId"
                >
                  <option value=""></option>
                  {genders?.map((gender) => (
                    <option value={gender.id} key={gender.id}>
                      {gender.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-12">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  readOnly
                  value={user?.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="institutionalEmail" className="form-label">
                  Email institucional
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="institutionalEmail"
                  readOnly
                  value={userInfo.studentData.institutionalEmail}
                  onChange={(e) => setInstitutionalEmail(e.target.value)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="phoneNumber" className="form-label">
                  Número de teléfono
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  value={phoneNumber || userInfo.studentData.phoneNumber || ''}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="cellphoneNumber" className="form-label">
                  Número de teléfono celular
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cellphoneNumber"
                  value={cellphoneNumber || userInfo.studentData.cellphoneNumber || ''}
                  onChange={(e) => setCellphoneNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mb-3">
            <button className="btn btn-success" type="submit">
              Actualizar información
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default StudentEditInformation;
