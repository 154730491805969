import axios from "axios";

export const getStudyGroupRequests = async () => { 
  try {
    const response = await axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/studyGroup/request`);

    return response.data.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const createStudyGroup = async (newStudyGroup) => {
  try {
    const response = await axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/studyGroup/create`, newStudyGroup);

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
