// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-type-button {
  border: 3px solid #ccc;
  height: 200px;
  width: 100%;
  border-radius: 15px;
  color: #bfbfbf;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  background-color: #f4f4f4;
}
.session-type-button:hover {
  background-color: #fff;
  border-color: var(--blue);
  color: var(--blue);
}
.session-type-button .card-title {
  margin-bottom: 0px;
}

a.session-type-button {
  text-decoration: none;
}
a.session-type-button:hover {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/features/tutors/selectRegisterFrom/SelectTutorRegisterForm.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;EACA,yBAAA;AACF;AACE;EACE,sBAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;;AAIA;EACE,qBAAA;AADF;AAGE;EACE,qBAAA;AADJ","sourcesContent":[".session-type-button {\n  border: 3px solid #ccc;\n  height: 200px;\n  width: 100%;\n  border-radius: 15px;\n  color: #bfbfbf;\n  cursor: pointer;\n  display: flex;\n  flex-wrap: wrap;\n  padding: 12px;\n  justify-content: center;\n  align-items: center;\n  transition: all 0.2s ease-in-out;\n  background-color: #f4f4f4;\n\n  &:hover {\n    background-color: #fff;\n    border-color: var(--blue);\n    color: var(--blue);\n  }\n\n  .card-title {\n    margin-bottom: 0px;\n  }\n}\n\na.session-type-button {\n  text-decoration: none;\n\n  &:hover {\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
