import axios from 'axios';

export const createStudyGroupRequest = async (studyGroupRequestData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/api/v1/studyGroup/request`,
      studyGroupRequestData
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const fetchFormData = async () => {
  try {
    //HARD CODE TABLES
    // console.log("entra a fetchFormData Service");
    const studentId = 100;
    const universityHeadquarters = [
      { id: 1, name: 'Universidad de Antioquia' },
      { id: 2, name: 'Universidas EAFIT' },
      { id: 3, name: 'Universidad de San Buenaventura' },
    ];
    const studyGroupShift = [
      { id: 1, name: 'Mañana' },
      { id: 2, name: 'Tarde' },
      { id: 3, name: 'Noche' },
    ];
    const studyModality = [
      { id: 1, name: 'Virtual' },
      { id: 2, name: 'Presencial' },
      { id: 3, name: 'Ambas' },
    ];
    const subjects = [
      { id: 1, name: 'Quimica' },
      { id: 2, name: 'Fisica' },
      { id: 3, name: 'Artistica' },
    ];
    const formData = {
      studyGroupId: 1,
      semester: 231,
      studyModality,
      studyGroupShift,
      universityHeadquarters,
      subjects,
      studentId,
    };
    let data = { formData: formData };
    const response = data;
    // console.log("Response: " + JSON.stringify(response));
    // const response = await axios
    //   .get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/studyGroupRequest`);
    // return response.data.data;
    return response;
  } catch (error) {
    throw error.response.data.error;
  }
};
