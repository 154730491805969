import React from 'react';
import { Link } from 'react-router-dom';
import './SelectTutorRegisterForm.scss';

const SelectTutorRegisterForm = () => {
  return (
    <div className="row justify-content-center">
      <div className="col col-md-8">
        <div className="card text-center">
          <div className="card-body">
            <h2 className="card-title">¿De qué quieres ser tutor?</h2>
            <p className="card-text">
              De acuerdo al tipo de sesión que quieras impartir, tendrás que llenar formularios diferentes.
            </p>
            <div className="row justify-content-center pt-4">
              <div className="col col-xl-4 mb-4">
                <a
                  className="session-type-button"
                  href={process.env.REACT_APP_STUDENTS_APP_URL + '/pages/registro_tutores.php'}
                >
                  <div>
                    <i className="fas fa-chalkboard-teacher fa-5x mb-3"></i>
                    <h5 className="card-title">Tutorías/Talleres</h5>
                  </div>
                </a>
              </div>
              <div className="col col-xl-4 mb-4">
                <Link className="session-type-button" to="/grupos-de-estudio/ser-tutor">
                  <div>
                    <i className="fas fa-users fa-5x mb-3"></i>
                    <h5 className="card-title">Grupos de estudio</h5>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTutorRegisterForm;
