import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './app/store';
import { fetchCurrentUser, logoutUser } from './features/users/userThunks';
import axios from 'axios';

const token = localStorage.getItem('jwt-atv');
axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logoutUser());
    }
    return Promise.reject(error);
  }
);

if (token) {
  store
    .dispatch(fetchCurrentUser())
    .unwrap()
    .catch(() => store.dispatch(logoutUser()))
    .finally(renderApp);
} else {
  renderApp();
}

function renderApp() {
  const rootElement = document.getElementById('root');
  if (!rootElement) {
    throw new Error('Could not find root element');
  }

  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
}
