import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatNumberUsingThousands } from '../../../../utils/numberFormat';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Chart = ({ total, successful, canceled }) => (
  <Line
    data={{
      labels: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      datasets: [
        {
          label: 'Tutorías totales',
          lineTension: 0.3,
          backgroundColor: 'rgba(78, 115, 223, 0.05)',
          borderColor: 'rgba(78, 115, 223, 1)',
          pointRadius: 3,
          pointBackgroundColor: 'rgba(78, 115, 223, 1)',
          pointBorderColor: 'rgba(78, 115, 223, 1)',
          pointHoverRadius: 3,
          pointHoverBackgroundColor: 'rgba(78, 115, 223, 1)',
          pointHoverBorderColor: 'rgba(78, 115, 223, 1)',
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: total,
        },
        {
          label: 'Tutorías exitosas',
          lineTension: 0.3,
          backgroundColor: 'rgba(40, 167, 69, 0.05)',
          borderColor: 'rgba(40, 167, 69, 1)',
          pointRadius: 3,
          pointBackgroundColor: 'rgba(40, 167, 69, 1)',
          pointBorderColor: 'rgba(40, 167, 69, 1)',
          pointHoverRadius: 3,
          pointHoverBackgroundColor: 'rgba(40, 167, 69, 1)',
          pointHoverBorderColor: 'rgba(40, 167, 69, 1)',
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: successful,
        },
        {
          label: 'Tutorías canceladas',
          lineTension: 0.3,
          backgroundColor: 'rgba(255, 60, 60, 0.05)',
          borderColor: 'rgba(255, 60, 60, 1)',
          pointRadius: 3,
          pointBackgroundColor: 'rgba(255, 60, 60, 1)',
          pointBorderColor: 'rgba(255, 60, 60, 1)',
          pointHoverRadius: 3,
          pointHoverBackgroundColor: 'rgba(255, 60, 60, 1)',
          pointHoverBorderColor: 'rgba(255, 60, 60, 1)',
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: canceled,
        },
      ],
    }}
    options={{
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          left: 10,
          right: 25,
          top: 25,
          bottom: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: 'rgb(255,255,255)',
          bodyColor: '#858796',
          titleMarginBottom: 10,
          titleColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function (context) {
              let datasetLabel = context.dataset.label || '';
              return datasetLabel + ': ' + formatNumberUsingThousands(context.parsed.y);
            },
          },
        },
      },
    }}
  />
);

export default Chart;
