import { createSlice } from '@reduxjs/toolkit';
import { fetchTutorshipsReport } from './reportsThunk';

const initialState = {
  /** @type {boolean} */
  isLoading: false,

  /** @type {string | null} */
  error: null,

  /** @type {Blob?} */
  data: null,
};

export const reportsSlice = createSlice({
  name: 'exportReports',
  initialState,
  reducers: {
    cleanupReports() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTutorshipsReport.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTutorshipsReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchTutorshipsReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const reportSelector = (state) => state.reports.data;
export const reportErrorSelector = (state) => state.reports.error;

export const { cleanupReports } = reportsSlice.actions;
