import React, { useEffect, useState } from 'react';
import Chart from './Chart';

import './AttendancesPerMonth.scss';

const AttendancesPerMonth = ({ data }) => {
  /** @type {[number[], Function]} */
  const [numAttendances, setNumAttendances] = useState([]);

  useEffect(() => {
    if (!data) return;
    let newNumAttendances = [];
    for (let month = 1; month <= 12; month++) {
      newNumAttendances.push(data[month]);
    }
    setNumAttendances(newNumAttendances);
  }, [data]);

  return (
    <div className="attendances-per-month-main-container card shadow mb-4 text-gray-900">
      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-center">
        <h5 className="m-0 font-weight-bold">Número de asistencias por mes</h5>
      </div>

      <div className="card-body">
        {numAttendances.length <= 0 ? (
          <h5 className="text-center">No tenemos datos para este apartado</h5>
        ) : (
          <div className="row justify-content-center h-100">
            <div className="col-10 h-100">
              <div className="chart-area h-100">
                <Chart attendances={numAttendances} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendancesPerMonth;
