import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTutorshipById, getTutorshipHistory, updateTutorship } from './tutorshipService';

export const fetchTutorshipById = createAsyncThunk(
  'tutorship/fetchById',
  /** @param {number} id */
  async (id) => {
    return await getTutorshipById(id);
  }
);

export const updateTutorshipData = createAsyncThunk(
  'tutorship/update',
  /** @param {any} updatedTutorshipData */
  async (updatedTutorshipData) => {
    return await updateTutorship(updatedTutorshipData);
  }
);

export const fetchTutorshipHistory = createAsyncThunk(
  'tutorship/history',
  async (tutorId = '') => {
    const result = await getTutorshipHistory(tutorId);
    return result;
  }
);

