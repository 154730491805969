import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAvailableYearsTutorshipStatistics, getTutorshipStatistics } from './tutorshipStatisticsService';

export const fetchTutorshipStatistics = createAsyncThunk(
  'tutors/fetchTutorshipStatistics',
  /** @param {string} year */
  async (year = '') => {
    const result = await getTutorshipStatistics(year);
    return result;
  }
);

export const fetchAvailableYearsTutorshipStatistics = createAsyncThunk(
  'tutors/fetchAvailableYearsTutorshipStatistics',
  async () => {
    const result = await getAvailableYearsTutorshipStatistics();
    return result;
  }
);
