import Swal from 'sweetalert2';

/** @param {import('sweetalert2').SweetAlertOptions} alertData */
export function showAlert(alertData) {
  return Swal.fire({
    icon: alertData.icon,
    title: alertData.title,
    text: alertData.text,
  });
}
