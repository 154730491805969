// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}
.form-check .form-check-input {
  margin-right: 5px;
}

.form-check-input-time-range {
  margin-right: 8px;
}

.day-column {
  min-width: 150px;
}

.tutor-type-checkbox {
  text-align: center;
  padding: 16px;
  border: 1px solid rgb(167, 166, 166);
}

table {
  border-color: #E3E6F0;
}`, "",{"version":3,"sources":["webpack://./src/features/tutors/register/RegisterTutor.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;AACF;AAAE;EACI,iBAAA;AAEN;;AACA;EACA,iBAAA;AAEA;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACE,kBAAA;EACA,aAAA;EACA,oCAAA;AAGF;;AAAA;EACA,qBAAA;AAGA","sourcesContent":[".form-check{\n  display: flex;\n  align-items: flex-start;\n  margin-bottom: 5px;\n  .form-check-input{\n      margin-right: 5px;\n  }\n}\n.form-check-input-time-range{\nmargin-right: 8px;\n}\n.day-column{\n  min-width: 150px;\n}\n\n.tutor-type-checkbox{\n  text-align: center;\n  padding: 16px;\n  border: 1px solid rgb(167, 166, 166);\n}\n\ntable{\nborder-color: #E3E6F0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
