import { createSlice } from '@reduxjs/toolkit';
import { fetchUniversities } from './universityThunk';

export const universitySlice = createSlice({
  name: 'university',
  initialState: {
    /** @type {boolean} */
    isLoading: false,

    /** @type {string | null} */
    error: null,

    /** @type {any?} */
    data: null,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUniversities.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUniversities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchUniversities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const universitiesSelector = (state) => state.university.data;
