import { createSlice } from '@reduxjs/toolkit';
import { fetchDepartments } from './departmentThunk';

export const departmentSlice = createSlice({
  name: 'department',
  initialState: {
    /** @type {boolean} */
    isLoading: false,

    /** @type {string | null} */
    error: null,

    /** @type {any?} */
    data: null,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDepartments.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const departmentsSelector = (state) => state.department.data;
