import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userAllInfoSelector } from '../../users/userSlice';
import { fetchUserById } from '../../users/userThunks';
import { isTutorUpdatedSelector, currentTutorSelector, tutorErrorSelector } from '../../tutors/tutorSlice';
import { Link, useParams } from 'react-router-dom';
import { fetchAllTutorInfoById, updateTutor } from '../../tutors/tutorThunks';
import { showAlert } from '../../common/alertService';
import SessionTypeEnum from '../../../Enums/SessionTypeEnum';
import TutorStatusEnum from '../../../Enums/TutorStatusEnum';
import TutorActionButtons from './TutorActionButtons';

import './TutorDetails.scss';
import { WORK_DAYS_OF_THE_WEEK, BLOCK_TIME_RANGES } from '../../common/constants';

const TutorDetails = () => {
  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();
  const { id } = useParams();

  const tutorData = useSelector(currentTutorSelector);
  const userData = useSelector(userAllInfoSelector);
  const successfullyUpdated = useSelector(isTutorUpdatedSelector);
  const error = useSelector(tutorErrorSelector);

  const [lastUpdate, setLastUpdate] = useState('');

  function printSubjects(subjects = []) {
    if (subjects.length === 0) {
      return 'Sin materias registradas';
    }
    return subjects.map((subject) => subject.name).join(', ');
  }

  const printSessionTypeNames = (sessionTypeIds = []) => {
    if (sessionTypeIds.length === 0) {
      return 'Sin registrar';
    }

    const sessionNames = {
      [SessionTypeEnum.TUTORSHIPS]: 'Tutorías',
      [SessionTypeEnum.STUDY_GROUPS]: 'Grupos de Estudio',
      [SessionTypeEnum.WORKSHOPS]: 'Talleres',
    };

    const sessionTypeNames = sessionTypeIds.map((sessionTypeId) => sessionNames[sessionTypeId]);

    return sessionTypeNames.join(', ');
  };

  const checkIfTimeRangeIsSelected = (weekDayNumber, timeRange) => {
    return tutorData?.availability.some(
      (availabilityTime) =>
        availabilityTime.dayOfWeek === weekDayNumber &&
        availabilityTime.blockStartTime === timeRange.start &&
        availabilityTime.blockEndTime === timeRange.end
    );
  };

  const updateTutorStatus = async (status) => {
    const validUpdateStatuses = [TutorStatusEnum.ACTIVE, TutorStatusEnum.DISABLED, TutorStatusEnum.REJECTED];

    if (validUpdateStatuses.includes(status)) {
      const tutorToUpdate = {
        id,
        startDate: tutorData?.startDate,
        finalDate: tutorData?.finalDate,
        status,
      };
      dispatch(updateTutor(tutorToUpdate));
    }
  };

  useEffect(() => {
    if (userData) {
      setLastUpdate(userData.lastUpdate.replace('T', ' ').replace('.000Z', ''));
    }
  }, [userData]);

  useEffect(() => {
    if (id) {
      dispatch(fetchUserById(parseInt(id)));
      dispatch(fetchAllTutorInfoById(parseInt(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (successfullyUpdated && id) {
      dispatch(fetchAllTutorInfoById(parseInt(id)));
    }
  }, [dispatch, id, successfullyUpdated]);

  useEffect(() => {
    if (!error) {
      return () => {};
    }
    if (!tutorData) {
      showAlert({ icon: 'error', title: 'Error', text: 'Error al consultar el tutor' });
      return () => {};
    }
    if (successfullyUpdated === false) {
      showAlert({ icon: 'error', title: 'Error', text: 'Error actualizando el tutor' });
    }
  }, [error, successfullyUpdated, tutorData]);

  if (!userData || !tutorData) {
    return <div>Cargando...</div>;
  }

  return (
    <React.Fragment>
      <div className="titleDetailsContainer mb-2">
        <h1 className="h3 mb-2 text-gray-800">Detalles del tutor</h1>
        <div className="d-flex flex-wrap justify-content-center gap-3">
          <TutorActionButtons status={tutorData.status} onNewStatusSelected={updateTutorStatus}/>
          <Link className="btn btn-warning m-1" to={`/tutores/editar/${id}`}>
            Editar
          </Link>
        </div>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header">
          <h6 className="font-weight-bold">Datos académicos</h6>
        </div>
        <div className="card-body">
          <div>
            <div className="row ">
              <div className="form-group col-12">
                <label htmlFor="name" className="form-label">
                  Estado
                </label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  id="name"
                  value={tutorData?.statusData.name}
                  disabled
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="university" className="form-label">
                  Universidad
                </label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  id="university"
                  value={userData?.studentData?.university?.name}
                  disabled
                />
              </div>
              <div className="form-group col-sm-8">
                <label htmlFor="career" className="form-label">
                  Programa
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="career"
                  readOnly
                  disabled
                  value={userData?.studentData?.career?.name}
                />
              </div>
              <div className=" form-group col-sm-4">
                <label htmlFor="academicLevel" className="form-label">
                  Nivel académico
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="academicLevel"
                  readOnly
                  disabled
                  value={userData?.studentData?.academicLevel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header">
          <h6 className="font-weight-bold">Datos personales</h6>
        </div>
        <div className="card-body">
          <div>
            <div className="row ">
              <div className="form-group col-12">
                <label htmlFor="nombre" className="form-label">
                  Nombre completo
                </label>
                <input type="text" className="form-control" readOnly id="nombre" value={userData?.fullName} disabled />
              </div>
              <div className="form-group col-12">
                <label htmlFor="email" className="form-label">
                  Correo
                </label>
                <input type="text" className="form-control" readOnly id="email" value={userData?.email} disabled />
              </div>
              <div className="form-group col-sm-8">
                <label htmlFor="celular" className="form-label">
                  Celular
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="celular"
                  readOnly
                  disabled
                  value={userData?.studentData?.cellphoneNumber}
                />
              </div>
              <div className=" form-group col-sm-4">
                <label htmlFor="phoneNumber" className="form-label">
                  Teléfono
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  readOnly
                  disabled
                  value={userData?.studentData?.phoneNumber}
                />
              </div>
              <div className="form-group col-sm-8">
                <label htmlFor="documento" className="form-label">
                  Documento
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="documento"
                  readOnly
                  disabled
                  value={userData?.documentNumber}
                />
              </div>
              <div className=" form-group col-sm-4">
                <label htmlFor="lastUpdate" className="form-label">
                  Última modificación
                </label>
                <input type="text" className="form-control" id="lastUpdate" readOnly disabled value={lastUpdate} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="font-weight-bold">Materias y Estrategias</h6>
          </div>
          <div className="card-body">
            <div className="container-fluid">
              <div className=" row ">
                <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Materia/s</th>
                      <th>Estrategia/s</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={tutorData?.id}>
                      <td>{printSubjects(tutorData?.subjects)}</td>
                      <td>{printSessionTypeNames(tutorData?.sessionTypeIds)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="font-weight-bold">Horarios</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table" border={1}>
                <thead>
                  <tr className="text-center">
                    {WORK_DAYS_OF_THE_WEEK.map((dayName) => (
                      <th key={dayName} scope="col" className="day-column">
                        {dayName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {BLOCK_TIME_RANGES.map((timeRange, index) => (
                    <tr key={index}>
                      {WORK_DAYS_OF_THE_WEEK.map((_dayName, dayIndex) => (
                        <td key={`${index}-${dayIndex}`}>
                          <label className="checkedOptionsLabel">
                            <input
                              className="form-check-input mr-2  checkedInput"
                              type="checkbox"
                              checked={checkIfTimeRangeIsSelected(dayIndex + 1, timeRange)}
                            />
                            {timeRange.label}
                          </label>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center ">
          <TutorActionButtons status={tutorData.status} onNewStatusSelected={updateTutorStatus}/>
          <Link className="btn btn-warning m-1" to={`/tutores/editar/${id}`}>
            Editar
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TutorDetails;
