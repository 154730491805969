import axios from 'axios';

export const loginRequest = async (loginData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/login`, loginData, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const logOutRequest = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/logout`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/current`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const setPassword = async (userData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/setPassword`, userData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const setUpdatePassword = async (passwordData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/updatePassword`, passwordData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const emailTorecoverPassword = async (userEmail) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/recoverPassword`, {
      email: userEmail,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const registerNewUser = async (newUserData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/register`, newUserData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const updateUserInformation = async (newUserInformation) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/`, newUserInformation, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
