import React from 'react';

const ButtonCard = ({ icon, title, data = null, color }) => {
  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div className={`card border-left-${color} shadow h-100 py-2`}>
        <div className="card-body">
          <div className="row no-gutters align-items-center h-100">
            <div className="col mr-2">
              <div
                className={`${title.length > 20 ? 'small' : ''} h5 font-weight-bold text-${color} text-uppercase mb-1`}
              >
                {title}
              </div>
              {data !== null ? (
                typeof data === 'object' ? (
                  Object.entries(data).map(([key, value]) => (
                    <>
                      <div className="text-m mb-0 font-weight-bold text-gray-800" key={key}>
                        {key}:
                      </div>
                      <span key={key}>{value}</span>
                    </>
                  ))
                ) : (
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{data}</div>
                )
              ) : (
                <></>
              )}
            </div>
            <div className="col-auto">{icon}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonCard;
