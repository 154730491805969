import React, { useState, useEffect } from 'react';

import Chart from './Chart';
import './TutorshipsPerMonth.scss';

const TutorshipsPerMonth = ({ data }) => {
  /** @type {[number[], Function]} */
  const [numTotal, setNumTotal] = useState([]);
  /** @type {[number[], Function]} */
  const [numSuccessful, setNumSuccessful] = useState([]);
  /** @type {[number[], Function]} */
  const [numCanceled, setNumCanceled] = useState([]);

  useEffect(() => {
    if (!data) return;
    let newNumTotal = [];
    let newNumSuccessful = [];
    let newNumCanceled = [];

    for (let month = 1; month <= 12; month++) {
      newNumTotal.push(data[month]?.total);
      newNumSuccessful.push(data[month]?.successful);
      newNumCanceled.push(data[month]?.canceled);
    }

    setNumTotal(newNumTotal);
    setNumSuccessful(newNumSuccessful);
    setNumCanceled(newNumCanceled);
  }, [data]);

  return (
    <div className="tutorships-per-month-main-container card shadow mb-4 text-gray-900">
      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-center">
        <h5 className="m-0 font-weight-bold">Número de tutorías por mes</h5>
      </div>

      <div className="card-body">
        {data == null || Object.keys(data || {}).length <= 0 ? (
          <h5 className="text-center">No tenemos datos para este apartado</h5>
        ) : (
          <div className="row justify-content-center h-100">
            <div className="col-10">
              <div className="chart-area h-100">
                <Chart total={numTotal} successful={numSuccessful} canceled={numCanceled} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TutorshipsPerMonth;
