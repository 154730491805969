import React from 'react';
import './SubjectsMenu.scss';

const SubjectsMenu = ({ subjects, currentSubject, onClick }) => (
  <nav className="subjects-menu-main-container nav nav-pills mb-4">
    {subjects.map((subject, i) => (
      <li
        className={`subjects-menu-item btn flex-sm-fill text-sm-center nav-link ${
          currentSubject === subject ? 'active' : ''
        }`}
        onClick={() => onClick(subject)}
        key={i}
      >
        <span>{subject}</span>
      </li>
    ))}
  </nav>
);

export default SubjectsMenu;
