import React from 'react';

const Comment = ({ headerData, content }) => (
  <div className="card w-100 p-2 border border-dark">
    {headerData ? (
      <div className="card-header">
        <div className="row">
          {headerData.map((category, i) => (
            <div className="py-1 px-2 rounded" key={i}>
              {category.label}: {category.value}
            </div>
          ))}
        </div>
      </div>
    ) : (
      <></>
    )}
    <div className="card-body">{content}</div>
  </div>
);

export default Comment;
