import axios from 'axios';

/** @param {string} year */
export const getTutorshipStatistics = async (year = '') => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_DOMAIN}/api/v1/statistics/tutorships/${year}`
        );
        return response.data;
    } catch (error) {
        throw error.response.data.error;
    }
};

export const getAvailableYearsTutorshipStatistics = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/v1/statistics/tutorships/availableYears`
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
