import { createSlice } from '@reduxjs/toolkit';
import { addStudyGroupRequest, getFormData } from './studyGroupRequestThunks';

export const studyGroupRequestSlice = createSlice({
  name: 'StudyGroupRequest',
  initialState: {
    isLoading: false,
    /** @type {string | null} */
    error: null,
    data: null,
    /** @type {any} */
    formData: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addStudyGroupRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addStudyGroupRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(addStudyGroupRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      })
      .addCase(getFormData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFormData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formData = action.payload;
      })
      .addCase(getFormData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const studyGroupRequestSelector = (state) => state.studyGroupRequest.data;
export const formDataSelector = (state) => state.studyGroupRequest.formData;
export const studyGroupRequestErrorSelector = (state) => state.studyGroupRequest.error;
export default studyGroupRequestSlice.reducer;
