import axios from 'axios';

export const updateStudentInformation = async (newStudentInformation) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_DOMAIN}/api/v1/user`, newStudentInformation);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
