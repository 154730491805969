import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStudyGroupRequests, createStudyGroup } from './studyGroupService';

export const fetchStudyGroupRequests = createAsyncThunk('studyGroup/getRequests', async () => {
  return await getStudyGroupRequests();
});

export const addNewStudyGroup = createAsyncThunk(
  'studyGroup/create',
  /** @param {any} studyGroupData */
  async (studyGroupData) => {
    return await createStudyGroup(studyGroupData);
  }
);
