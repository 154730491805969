// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#editTutorshipTitle, #editTutorshipText {
  color: #3058C9;
  font-weight: bold;
}

.editTutorshipLogo {
  width: 125px;
}`, "",{"version":3,"sources":["webpack://./src/features/admin/tutorship/EditTutorship.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":["#editTutorshipTitle, #editTutorshipText{\n  color: #3058C9;\n  font-weight: bold;\n}\n\n.editTutorshipLogo{\n  width: 125px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
