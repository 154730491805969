import { createSlice } from '@reduxjs/toolkit';
import { fetchSubjects } from './subjectThunk';
export const subjectSlice = createSlice({
  name: 'subject',
  initialState: {
    /** @type {boolean} */
    isLoading: false,

    /** @type {string | null} */
    error: null,

    /** @type {any?} */
    data: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSubjects.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSubjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchSubjects.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const subjectsSelector = (state) => state.subject.data;
