import React from 'react';
import PropTypes from 'prop-types';
import TutorStatusEnum from '../../../Enums/TutorStatusEnum';

const STATUS_TRANSITIONS = {
  [TutorStatusEnum.INACTIVE]: [TutorStatusEnum.ACTIVE, TutorStatusEnum.REJECTED],
  [TutorStatusEnum.REJECTED]: [TutorStatusEnum.ACTIVE],
  [TutorStatusEnum.DISABLED]: [TutorStatusEnum.ACTIVE],
  [TutorStatusEnum.ACTIVE]: [TutorStatusEnum.DISABLED],
};

const BUTTON_NAME_PER_STATUS = {
  [TutorStatusEnum.ACTIVE]: 'Aceptar',
  [TutorStatusEnum.REJECTED]: 'Rechazar',
  [TutorStatusEnum.DISABLED]: 'Deshabilitar',
};

/** @param {{ status: number, onNewStatusSelected: (newStatus: number) => void, className?: string }}  props*/
const TutorActionButtons = ({ status, onNewStatusSelected, className }) => {
  return (
    <div className={className || ''}>
      {STATUS_TRANSITIONS[status].map((newStatus) => (
        <button
          key={newStatus}
          onClick={() => onNewStatusSelected(newStatus)}
          className={`btn btn-${newStatus === TutorStatusEnum.ACTIVE ? 'success' : 'danger'} m-1`}
        >
          {BUTTON_NAME_PER_STATUS[newStatus]}
        </button>
      ))}
    </div>
  );
};

TutorActionButtons.propTypes = {
  status: PropTypes.oneOf(Object.values(TutorStatusEnum)).isRequired,
  onNewStatusSelected: PropTypes.func.isRequired,
};

export default TutorActionButtons;
