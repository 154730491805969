import randomColor from 'randomcolor';

export const baseColors = [
  '#dc3545', // red
  '#0d6efd', // blue
  '#ffc107', // yellow
  '#198754', // green
  '#fd7e14', // orange
  '#20c997', // teal
];
export function getColors(count) {
  const result = [];
  for (let i = 0; i < baseColors.length; i++) {
    if (count <= 0) return result;
    result.push(baseColors[i]);
    count--;
  }
  let randomColors = randomColor({ count: count });
  return result.concat(randomColors);
}
