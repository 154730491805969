import axios from 'axios';

export const getTutorshipById = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/tutorship/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const updateTutorship = async (updatedTutorshipData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_DOMAIN}/api/v1/tutorship/`, updatedTutorshipData);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const getTutorshipHistory = async (tutorId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/api/v1/tutorship/history?tutorId=${tutorId}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
