import { createSlice } from "@reduxjs/toolkit";
import { fetchCareersByUniversityId } from "./careerThunk";

export const careerSlice = createSlice({
  name: 'career',
  initialState: {
    /** @type {boolean} */
    isLoading: false,

    /** @type {string | null} */
    error: null,

    /** @type {any?} */
    data: null,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCareersByUniversityId.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCareersByUniversityId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchCareersByUniversityId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const careersSelector = (state) => state.career.data;
