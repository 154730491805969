import React, { useState, useRef, useEffect } from 'react';
import './Login.css';
import Logo from '../../../assets/img/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../userThunks';
import { redirectUrlSelector, userErrorSelector, userSelector } from '../userSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import { showAlert } from '../../common/alertService';

const LoginInicio = () => {
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const userError = useSelector(userErrorSelector);
  const redirectUrl = useSelector(redirectUrlSelector);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);

  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();

  /** @type {React.MutableRefObject<ReCAPTCHA|null>} */
  const captchaRef = useRef(null);

  const handleLogin = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (captchaRef.current?.getValue()) {
      dispatch(login({ email, password }));
    } else {
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: 'Realiza la validación Captcha',
      });
    }
  };

  useEffect(() => {
    if (user?.mandatoryPasswordChange === true) {
      navigate('/registrar-contrasena');
      return;
    }

    if (!userError && user) {
      return redirectUrl ? window.location.replace(redirectUrl) : navigate('studyGroup/create');
    }

    if (isSubmitted && userError) {
      showAlert({
        icon: 'error',
        title: 'Oops...',
        text: userError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, user, userError, redirectUrl]);

  if (user) {
    return <div></div>;
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <img className="logo-login" src={Logo} alt="Logo" />
                      <h1 className="h2">¡Bienvenido de nuevo!</h1>
                    </div>
                    <form className="user" onSubmit={handleLogin}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-user"
                          name="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          aria-describedby="emailHelp"
                          placeholder="Introduce tu dirección de correo electrónico"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Introduce tu contraseña"
                          required
                        />
                      </div>
                      <div className="form-group"></div>
                      <hr />
                      <ReCAPTCHA
                        ref={captchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      />
                      <hr />
                      <button className="btn btn-primary btn-user btn-block">Iniciar sesión</button>
                    </form>
                    <hr></hr>
                    <div className="text-center">
                      <Link className="small" to={`/registrarse`}>
                        Crear cuenta
                      </Link>
                    </div>
                    <div className="text-center">
                      <Link className="small" to={`/recuperar-clave`}>
                        He olvidado mi contraseña
                      </Link>
                    </div>
                    <hr />
                    <div className="text-center">
                      <a className="small" href="https://fundacionantivirusparaladesercion.org/">
                        Fundación Antivirus para la deserción
                      </a>
                    </div>
                    <div className="text-center">
                      <a className="small" href="https://fundacionantivirusparaladesercion.org/">
                        2024
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginInicio;
