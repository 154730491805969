// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-card-main-container {
  overflow-y: auto;
  max-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/features/statistics/components/QualitativeEvaluations/components/CategoryCard.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".category-card-main-container {\n  overflow-y: auto;\n  max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
